<template>
  <div class="pr-4 pb-4">
    <TextField v-model="input" @input="input"/>
  </div>
</template>

<script>
import TextField from "@/views/student/pte-practice/item/QuestionTypeElements/textFieldBox.vue";
export default {
    components: {
      TextField
    },
    props:{
      quiz: {
        type: Object,
      },
    },
    data() {
      return {
        form: {
          transcript: ''
        }
      }
    },
    methods: {
      input(value) {
          this.answer_options = {quiz_options: [],answer: value, id: this.quiz.id};
          this.$emit('input', this.answer_options)
      }
    }
}
</script>