<template>
  <div class="p-4 pb-0">
    <md-content  class="md-content md-sl__font_600 mb-4">
     {{quiz.index}}
    </md-content>
    <md-content  class="md-content md-sl__font_600 mb-4" v-html="quiz.title">
    </md-content>
    <md-content class="mb-4 p-4 md-sl__q_color br-4" v-html="quiz.transcript"></md-content>
    <Audio :src="quiz.audio_resource_path"/>
  </div>
</template>

<script>
import Audio from '@/components/molecule/question/audio.vue'
export default {
    components: {
       Audio
    },
    props:{
      quiz: {
        type: Object,
      },
    },
    data() {
      return {
       // src: 'https://kazi-blubird.sfo2.digitaloceanspaces.com/apical/speaking/audio/speaking_1658458085802.wav'
      }
    },
}
</script>