<template>
  <div class="p-4 pb-0">
    <md-content  class="md-content md-sl__font_600 mb-4">
      {{ quiz.index }}
    </md-content>
    <md-content  class="md-content md-sl__font_600 mb-4" v-html="quiz.title">
    </md-content>
    <md-content class="mb-4 p-4 md-sl__q_color br-4" v-html="quiz.transcript"></md-content>
    <md-content class="mb-4 p-4 md-sl__q_color br-4">
      <img :src="quiz.image_resource_path"/>
    </md-content>
  </div>
</template>

<script>

export default {
    components: {
       
    },
    props:{
      quiz: {
        type: Object,
      },
    },
    data() {
      return {
         file: 'https://cdn.corporatefinanceinstitute.com/assets/10-Poor-Study-Habits-Opener.jpeg',
      }
    },
}
</script>