<template>
  <div class="p-4 pb-0">
    <md-content  class="md-content md-sl__font_600 mb-4">
     {{ quiz.index }}
    </md-content>
    <md-content  class="md-content md-sl__font_600 mb-4" v-html="quiz.title">
    </md-content>
    <md-content class="mb-4 p-4 md-sl__q_color br-4" v-html="quiz.transcript"></md-content>
    <md-content class="mb-4 p-4 md-sl__q_color br-4">
      <span v-if="quiz.video_resource_path.match('youtube')">
        <iframe 
          id="player" 
          type="text/html" 
          style="width: 80%;height: 45vh !important;"
          :src="videoUrl(quiz.video_resource_path)"
          frameborder="0">
        </iframe>
      </span>  
      <span v-if="!quiz.video_resource_path.match('youtube')">
        <video controls style="width: 60%;">
          <source :src="quiz.video_resource_path" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
      </span>
    </md-content>
  </div>
</template>

<script>
import { getEmbeddedUrl } from "@/utils/videoHelper";
export default {
    components: {
       
    },
    props:{
      quiz: {
        type: Object,
      },
    },
    data() {
      return {
         //file: 'https://www.youtube.com/watch?v=NiQVN_YeWSw&list=RDNiQVN_YeWSw&start_radio=1',
      }
    },
    methods: {
      videoUrl(url) {
        return getEmbeddedUrl(url);
      },
    }
}
</script>