<template>
     <div class="p-4 pt-0">
        <div class="pl-4 md-question__radio md-sl__font_600 md-sl__content_color">
            <li v-for="(item, index) in quiz.quiz_options" :key="index" class="flex align-center">
                <md-checkbox v-model="array" :value="index"></md-checkbox>
                <span>{{item.value}}</span>
            </li>
        </div>
    </div>
</template>
<script>
export default {
    name: 'multiple',
    props:{
      quiz: {
        type: Object,
      },
    },
    data() {
      return {
          array: [],
          disable: 1,
          list:'',
          answer_options: [],
          form: {
            radio: ''
          }
      }
    },
    mounted(){
        this.list = this.quiz.quiz_options.sort(() => Math.random() - 0.5);
        this.list.forEach((item,i) => {
              item.new_serial = i+1;
        });
    },
    watch: {
      array(value) {
        this.quiz.quiz_options.map(item => delete item.drop_down_options);
        this.quiz.quiz_options.forEach((item) => {
              item.is_correct_answer = false ;
          });
          for( var i = 0; i < value.length; i++){
              let x = value[i]
              this.quiz.quiz_options[x].is_correct_answer = true;
          }
          this.answer_options = {answer: null,quiz_options: this.quiz.quiz_options,id: this.quiz.id};
          this.$emit('input', this.answer_options)
          console.log(this.answer_options,this.quiz)
      }
    }
}
</script>