<template>
     <div class="p-4 pt-0">
        <div class="md-sl__content_color" style="padding: 0.1px;">
            <ul>
                <li v-for="(item, index) in quiz.quiz_options" :key="index" class="mx-1 flex align-center">
                    <md-radio v-model="radio" :value="index" class="md-primary ml-2"> </md-radio>
                    <span>{{item.value}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'single',
    props:{
      quiz: {
        type: Object,
      },
    },
    data() {
      return {
        radio: '1', 
        answer_options: []
      }
    },
    mounted(){
      this.list = this.quiz.quiz_options.sort(() => Math.random() - 0.5);
    },
    watch: {
        radio(value) {
          this.quiz.quiz_options.map(item => delete item.drop_down_options);
            this.quiz.quiz_options.forEach((item) => {
              item.is_correct_answer = false ;
            });
            this.quiz.quiz_options[value].is_correct_answer = true;
            const data = this.quiz.quiz_options
            this.answer_options = {answer: null, quiz_options: data, id: this.quiz.id};
            this.$emit('input', this.answer_options)
            console.log(this.answer_options)
        }
    }
}
</script>